.CountryContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (max-width: 590px) {
  .CountryContainer {
    flex-direction: column;
  }
}