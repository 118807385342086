.Dropdown {
  display: flex;
  width: calc(50% - 10px);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: #ffffff;
}

.Dropdown img {
  height: 61px;
}

.Dropdown > select {
  appearance: none;
  border: 0;
  color: #333333;
  width: 100%;
  padding: 20px;
  font-size: 16px;
  letter-spacing: 1px;
  padding-right: 40px;
}

.Dropdown::after {
  content: " ";
  display: block;
  position: absolute;
  border: 6px solid transparent;
  border-top-color: #333333;
  right: 20px;
  top: calc(50% - -3px);
  transform: translateY(-50%);
}

@media (max-width: 590px) {
  .Dropdown {
    width: 100%;
  }

  .Dropdown:not(:first-of-type){
    margin-top: 20px;
  }
}
