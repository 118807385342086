.Footer{
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  position: absolute;
  font-size: 13px;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 590px) {
  .Footer{
    padding: 10px;
  }
}