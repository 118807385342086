body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #4158d0, #c850c0);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 60px);
  padding-bottom: 60px;
  position: relative;
}

.App{
  padding-top: 34px;
}

table {
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

table thead tr {
  height: 60px;
  background: #36304a;
}

table thead th {
  text-align: left;
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

table tr:nth-child(odd) td {
  background-color: #f5f5f5;
}

table tr td:first-of-type,
table tr th:first-of-type {
  padding-left: 40px;
}

table tr td:last-of-type,
table tr th:last-of-type {
  padding-right: 40px;
}

td,
th {
  padding: 10px;
}

th {
  font-size: 18px;
  color: #fff;
}

tbody tr {
  color: #6f6f6f;
}

tbody tr:hover {
  color: #333333;
}

.subText {
  display: block;
  font-size: 12px;
}

main {
  margin: 0 auto;
  max-width: 900px;
  width: calc(100% - 60px);
  box-sizing: border-box;
  padding-top: 10px;
}

@media (max-width: 590px) {
  main {
    width: calc(100% - 20px);
  }

  table tr td:first-of-type,
  table tr th:first-of-type {
    padding-left: 10px;
  }

  table tr td:last-of-type,
  table tr th:last-of-type {
    padding-right: 10px;
  }
}
